<template>
  <div>
    <a-card :bordered="false" :headStyle="{ 'padding-bottom': 0 }">
      <a-button
        v-if="$auth('order_cannel') && orderStatus == 'TO_BE_PAY'"
        slot="extra"
        type="primary"
        shape="round"
        @click="onCancel"
        >取消订单</a-button
      >
      <div slot="title" class="title error-color">
        <a-icon style="margin-right:4px" theme="filled" type="exclamation-circle" />当前订单状态：{{
          orderStatus | OrderStatusText
        }}
      </div>
    </a-card>

    <a-card :bordered="false">
      <a-row type="flex" align="middle" style="padding-bottom:20px;font-size:16px">
        <a-icon style="margin-right:6px" type="tag" theme="filled" :rotate="135" />
        <a-col>基本信息</a-col>
      </a-row>
      <a-table bordered rowKey="code" :data-source="form" :pagination="false">
        <a-table-column key="code" title="订单编号" align="center" data-index="code" />
        <a-table-column key="cellphone" title="用户账号" align="center" data-index="cellphone" />
        <a-table-column key="paymentMethod" title="支付方式" align="center">
          <template slot-scope="record">
            {{ record.paymentMethod | OrderPaymentMethod }}
          </template>
        </a-table-column>
        <a-table-column key="mechanismName" title="订单来源" align="center" data-index="mechanismName" />
        <a-table-column key="createTimestamp" title="创建时间" align="center" data-index="createTimestamp" />
        <a-table-column key="payTimestamp" title="付款时间" align="center" data-index="payTimestamp" />
        <a-table-column key="transactionTimestamp" title="成交时间" align="center" data-index="transactionTimestamp" />
        <a-table-column key="paymentCode" title="交易号" data-index="paymentCode" />
      </a-table>
      <a-row type="flex" align="middle" style="padding:20px 0;font-size:16px">
        <a-icon style="margin-right:6px" type="tag" theme="filled" :rotate="135" />
        <a-col>商品信息</a-col>
      </a-row>
      <a-table bordered rowKey="code" :data-source="form" :pagination="false">
        <a-table-column key="coverUrl" title="商品图片" align="center">
          <template slot-scope="record">
            <a-avatar style="width: 142px; height: 80px" shape="square" :src="record.coverUrl"></a-avatar>
          </template>
        </a-table-column>
        <a-table-column key="goodsName" title="商品名称" align="center" data-index="goodsName" />
        <a-table-column key="type" title="类型" align="center">
          <template slot-scope="record">
            <span>{{ record.type | OrderGoodsType }}</span>
          </template>
        </a-table-column>
        <a-table-column key="goodsSpec" title="规格" align="center" data-index="goodsSpec" />
        <a-table-column key="goodsSpecVal" title="值" align="center" data-index="goodsSpecVal" />
      </a-table>
      <a-row type="flex" align="middle" style="padding:20px 0;font-size:16px">
        <a-icon style="margin-right:6px" type="tag" theme="filled" :rotate="135" />
        <a-col>费用信息</a-col>
      </a-row>
      <a-table bordered rowKey="code" :data-source="form" :pagination="false">
        <a-table-column key="originPrice" title="商品价格" align="center" data-index="originPrice" />
        <a-table-column key="discount" title="折扣" align="center" data-index="discount" />
        <a-table-column key="favourable" title="优惠" align="center" data-index="favourable" />
        <a-table-column key="orderPrice" title="订单金额" align="center" data-index="orderPrice" />
        <a-table-column key="paymentPrice" title="应付款金额" align="center" data-index="paymentPrice" />
      </a-table>
    </a-card>
  </div>
</template>

<script>
import * as orderApi from '@/api/order'
export default {
  data() {
    return {
      form: [],
      orderStatus: undefined,
      orderId: undefined
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      this.orderId = id
      this.getData()
    },
    getData() {
      orderApi.detail(this.orderId).then(res => {
        const { status } = res.data
        this.orderStatus = status
        this.form = [res.data]
      })
    },
    onCancel() {
      const confirm = this.$confirm({
        title: '取消订单',
        content: '您确定要取消吗',
        onOk: () => {
          return orderApi
            .remove(this.orderId)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可取消',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
